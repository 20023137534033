<template>
  <div class="col-12">
    <div class="card-body card">
        <h5>
            المهام <button class="btn btn-sm btn-success" v-b-modal.create v-if="canAdd">
                <i class="fa fa-plus"></i>
                إضافة
            </button>
        </h5>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        المشرف
                    </th>
                    <th>
                        المهمة
                    </th>
                    <th>
                        التفاصيل
                    </th>
                    <th>
                        التاريخ
                    </th>
                </thead>
                <tbody>
                    <tr v-for="item in list" :key="item._id">
                        <td>
                            {{ item.admin_name }}
                        </td>
                        <td>
                            {{ item.title }}
                        </td>
                        <td>
                            {{ item.details }}
                        </td>
                        <td>
                            {{ item.date }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="create" title="اضافة" hide-footer>
        <div class="form-group">
          <h5 for="">العنوان</h5>
          <input type="text"
            class="form-control" placeholder="اكتب هنا..." v-model="create.title">
        </div>
        <div class="form-group">
          <h5 for="">التفاصيل</h5>
          <textarea class="form-control" placeholder="اكتب هنا..." v-model="create.details" rows="3"></textarea>
        </div>
        <div class="form-group">
          <h5 for="">المشرف</h5>
          <select class="form-control" v-model="create.admin_id">
            <option :value="undefined">-- اختر --</option>
            <option v-for="admin in admins" :key="admin._id" :value="admin._id">{{ admin.name }}</option>
          </select>
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="addNow()">
                اضافة المهمة
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            current: {},
            create: {},
            admins: [],
            user: JSON.parse(localStorage.getItem("user")),
            canAdd: checkPer('add-tasks'),
            list: []
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/admins/names', {
            jwt: g.user.jwt
        }).then(function(r){
            g.admins = r.response;
        })
        g.gett()
    },
    methods: {
        gett(){
            var g = this;
            $.post(api + '/admin/web/tasks/list', {
                jwt: g.user.jwt
            }).then(function(r){
                g.list = r.response
            })
        },
        addNow(){
            var g = this;
            $.post(api + '/admin/web/tasks/create', {
                jwt: g.user.jwt,
                title: g.create.title,
                details: g.create.details,
                admin_id: g.create.admin_id,
            }).then(function(r){
                g.gett()
                alert("تم الاضافة بنجاح", 100)
                $("#create___BV_modal_header_ > button").click()
            })
        }
    }
}
</script>

<style>

</style>